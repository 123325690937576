import api from './base';

export default{
  async deleteSchool(payload) {
    const result = await api().delete('/api/School/' + payload);
    return result.data;
  },
  async createSchool(payload) {
    const result = await api().post('/api/School', payload);
    return result;
  },
  async getSchoolList() {
    const result = await api().get('/api/School/GetActives');
    return result.data;
  },
}