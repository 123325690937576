<template>
  <div>
    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-4">
        <a-select
          showSearch
          placeholder="Select a School"
          optionFilterProp="children"
          class="select-box"
          @select="handleChangeSchool"
          :filterOption="filterOption">
            <a-select-option 
              v-for="school in schoolList"
              :key="school.id" 
              :value="school.id">
                {{school.Name}}
            </a-select-option>
        </a-select>
      </div>
    </div>
    <a-table
      :columns="columns" 
      :dataSource="campusList" 
      :pagination="false"
      :loading="tableLoading"
      rowKey="id">
        <span slot="status" slot-scope="tag">
          <a-tag :color="(tag === 1 ? 'green' : 'volcano')">
            {{tag === 1 ? 'Active' : 'Deleted'}}
          </a-tag>
        </span>
    </a-table>
  </div>
</template>
<script>
import teacherService from '@/services/teacher';

const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Login Code',
      dataIndex: 'LoginCode',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      scopedSlots: { customRender: 'status' }
    }
  ];

export default {
  props: ['schoolList'],
  data(){
    return {
      campusList: [],
      columns,
      tableLoading: false,
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleChangeSchool(schoolId) {
      this.tableLoading = true;
      const result = await teacherService.getTeachers(schoolId);
      this.campusList = result;
      this.tableLoading = false;
    }
  }
}
</script>
<style scoped>
.select-box {
  width: 100%;
  margin: 10px 0px;
}
</style>