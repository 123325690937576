import api from './base';

export default{
  async getTeachers(payload) {
    const result = await api().get('/api/Teacher/GetBySchool/' + payload);
    return result.data;
  },
  async createTeacher(payload) {
    const result = await api().post('/api/Teacher', payload);
    return result.data;
  },
  async generateCode() {
    const result = await api().get('/api/Teacher/GenerateKey');
    return result.data;
  },
}